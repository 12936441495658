<template>
  <div>
    <vx-card>
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs v-model="activeTab">
          <vs-tab label="Information" icon-pack="feather" icon="icon-user">
            <div class="tab-text mt-3">
              <info :id="$route.params.id"></info>
            </div>
          </vs-tab>
          <vs-tab label="Change Password" icon-pack="feather" icon="icon-key">
            <div class="tab-text mt-3">
              <change-password :id="$route.params.id"></change-password>
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>

<script>
import Info from './sections/Info.vue'
import ChangePassword from './sections/ChangePassword.vue'
export default {
  data(){
    return {
      activeTab: 0
    }
  },
  components:{
    Info,
    ChangePassword
  }
}
</script>

<style>

</style>