<template>
  <div class="w-full">
    <table class="info">
      <tr>
        <td class="font-semibold">Name</td>
        <td>{{data.name}}</td>
      </tr>
      <tr>
        <td class="font-semibold">Email</td>
        <td>{{data.email}}</td>
      </tr>
      <tr>
        <td class="font-semibold">Contact</td>
        <td>{{data.contact_name}}</td>
      </tr>
      <tr>
        <td class="font-semibold">Role</td>
        <td>{{data.role_name}}</td>
      </tr>
      <tr>
        <td class="font-semibold">Status</td>
        <td>
          <vs-chip v-if="data.is_admin" color="primary">Administrator</vs-chip>
          <vs-chip v-if="!data.is_admin">Member</vs-chip>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: ['id'],
  data(){
    return {
      data:{}
    }
  },
  methods:{
    ...mapActions({
      dispatchShow: `users/show`
    }),
    async getData(){
      let {data} = await this.dispatchShow(this.id)
      this.data = data
    }
  },
  mounted(){
    this.getData()
  }
}
</script>

<style>

</style>