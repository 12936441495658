<template>
  <div class="sm:w-full md:w-1/2">
    <div class="vx-row mb-3">
      <div class="vx-col w-full">
        <vs-input type="password" class="w-full" v-validate="'required'" data-vv-validate-on="blur" name="old_password" autocomplete="new-password" label="Old Password" v-model="old_password"></vs-input>
        <span class="text-danger text-sm" v-show="errors.has('old_password')">{{errors.first('old_password')}}</span>
        <span class="text-danger text-sm" v-show="formError.old_password" v-for="(tr,i) in formError.old_password" :key="i">{{tr}}</span>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col w-full">
        <vs-input ref="password" type="password" class="w-full" v-validate="'required'" data-vv-validate-on="blur" name="password" autocomplete="new-password" label="Password" v-model="password"></vs-input>
        <span class="text-danger text-sm" v-show="errors.has('password')">{{errors.first('password')}}</span>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col w-full">
        <vs-input type="password" class="w-full" v-validate="'required|confirmed:password'" name="password_confirmation" data-vv-validate-on="blur" label="Password Confirmation" v-model="password_confirmation"></vs-input>
        <span class="text-danger text-sm" v-show="errors.has('password_confirmation')">{{errors.first('password_confirmation')}}</span>
      </div>
    </div>
    <vs-divider></vs-divider>
    <vs-button @click="store" color="primary" icon-pack="feather" icon="icon-save">Save</vs-button>&nbsp;
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props:['id'],
  data(){
    return {
      old_password:"",
      password:"",
      password_confirmation:"",
      formError: {}
    }
  },
  methods:{
    ...mapActions({
      dispatchChangePw: `users/change_password`
    }),
    store(){
      this.formError={}
      this.$validator.validateAll().then(async res => {
        if(!res) return false;
        let payload = {
          id: this.id,
          old_password: this.old_password,
          password: this.password,
        }
        try {
          await this.dispatchChangePw(payload)
          this.old_password=""
          this.password=""
          this.password_confirmation=""
          this.$vs.notify({
            title: 'Success!',
            text: "Your password successfully changed",
            color: 'success'
          })
        } catch(error) {
          if (error.status==422) {
            this.formError = error.data
          } else {
            this.$vs.notify({
              title: 'Oops!',
              text: error.data.message,
              color: 'danger'
            })
          }
        }
      })
    }
  }
}
</script>

<style>

</style>